<template>
<div class="p-fluid" style="margin-top: 10px">
    <div class="p-grid">
        <div class="p-col-6">
            <label for="optional_tour">Optional tour:</label>
            <Dropdown v-model="selectedOptionalTour" @change="onOptionalTourSelect()" :options="optionalTour" optionLabel="desc" :class="{'p-invalid':v_bookingoptour$.optionaltour_id.$error}" />
        </div>
        <div class="p-col-6">
            <label for="optionaltour_date">Date:</label>
            <Calendar id="optionaltour_date" v-model="optionalTourBookingForm.booking_date" :monthNavigator="true" :yearNavigator="true" yearRange="2010:2040" dateFormat="dd-M-yy" style="height: 2.15rem;" :class="{'p-invalid':v_bookingoptour$.booking_date.$error || !isBookingDateValid}" />
        </div>
    </div>
    <div class="p-grid" style="align-items: flex-end;">
        <div class="p-col">
            <label for="pax">Pax:</label>
            <InputNumber id="pax" v-model="optionalTourBookingForm.pax" :class="{'p-invalid':v_bookingoptour$.pax.$error}" />
        </div>
        <div class="p-col">
            <label for="child">Child:</label>
            <InputNumber id="child" v-model="optionalTourBookingForm.child" />
        </div>
    </div>
    <div class="p-grid">
        <div v-if="errorMessage == ''" class="p-col-fixed" style="padding-top: 15px; align-self: flex-end">
            <Button v-if="!isEditOptionalTourBooking" label="Save Optional Tour Booking" @click="saveOptionalTourBooking()" class="p-button-success p-button-sm" style="width: 185px;"></Button>
            <Button v-if="isEditOptionalTourBooking" label="Update Optional Tour Booking" @click="updateOptionalTourBooking()" class="p-button-success p-button-sm p-mr-1" style="width: 200px" />
        </div>
        <div v-if="errorMessage != ''" class="p-col" style="padding-top: 15px;">
            <InlineMessage severity="warn">{{ errorMessage }}</InlineMessage>
        </div>
    </div>
</div>
</template>

<script>
import { ref, reactive, onMounted, computed } from 'vue'
import { useToast } from "primevue/usetoast";
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import BookingService from '../service/BookingService';
import ItineraryService from '../service/ItineraryService';

export default {
    props: {
        BookingID: Number,
        ItineraryID: Number
    },
    emits: ['RefreshOptionalTourBooking'],
    setup(props, { emit }) {
        const toast = useToast()
        const optionalTour = ref([])
        const selectedOptionalTour = ref()
        let errorMessage = ref('')

        let optionalTourBookingForm = reactive({})
        let isEditOptionalTourBooking = ref(false)
        let isBookingDateValid = ref(true)

        const bookingService = ref(new BookingService())
        const itinService = ref(new ItineraryService())

        onMounted(() => {
            if(props.ItineraryID) {
                setOptionalTourDropdown(props.ItineraryID)
            } else {
                errorMessage.value = "This booking doesn't have an itinerary."
            }
        })

        //=============Method=============
        const setOptionalTourDropdown = (itin_ID) => {
            itinService.value.getOptionalTour(itin_ID).then(data => {
                if(!data.errorResponse) {
                    if(data.length > 0) {
                        optionalTour.value = data

                        if(optionalTourBookingForm.optionaltour_id != 0) {
                            selectedOptionalTour.value = optionalTour.value.find(item => item.id === optionalTourBookingForm.optionaltour_id)
                        }
                    } else {
                        errorMessage.value = 'No optional tour available for the itinerary of this booking.'
                    }
                }
            })
        }

        const onOptionalTourSelect = () => {
            if(selectedOptionalTour.value.id) {
                optionalTourBookingForm.optionaltour_id = selectedOptionalTour.value.id
            }
        }

        const initOptionalTourBookingForm = () => {
            const optionaltour_booking_form = {
                id: '',
                booking_date: '',
                pax: null,
                child: null,
                booking_id: '',
                optionaltour_id: ''
            }

            selectedOptionalTour.value = null

            Object.assign(optionalTourBookingForm, optionaltour_booking_form)
        }

        const saveOptionalTourBooking = () => {
            if(validateForm() && props.BookingID != 0) {
                optionalTourBookingForm.booking_id = props.BookingID
                
                bookingService.value.addBookingOptionalTour(optionalTourBookingForm).then(data => {
                    if(!data.errorResponse) {
                        if(data.status == 400) {
                            isBookingDateValid.value = false
                            toast.add({severity:'warn', summary: 'Save Error', detail: data.message, life: 4500}); 
                        } else {
                            initOptionalTourBookingForm()
                            v_bookingoptour$.value.$reset();
                            isBookingDateValid.value = true
                            emit('RefreshOptionalTourBooking', props.BookingID, props.ItineraryID)
                            toast.add({severity:'success', summary: 'Success', detail:'Optional tour booking has been created.', life: 3000}); 
                        }
                    } else {
                        toast.add({severity:'warn', summary: 'Error ' + data.status, detail: data.errorResponse, life: 4500});        
                    }
                })
            }
        }

        const editOptionalTourBooking = (emitData) => {
            isEditOptionalTourBooking.value = true
            initOptionalTourBookingForm()
            
            Object.keys(optionalTourBookingForm).forEach((key)=>{
                optionalTourBookingForm[key] = emitData[key]
            })
            
            optionalTourBookingForm.booking_date = new Date(optionalTourBookingForm.booking_date)
        }

        const updateOptionalTourBooking = () => {
            if(validateForm() && props.BookingID != 0) {
                bookingService.value.updateBookingOptionalTour(optionalTourBookingForm.id, optionalTourBookingForm).then(data => {
                    if(!data.errorResponse) {
                        if(data.status == 400) {
                            isBookingDateValid.value = false
                            toast.add({severity:'warn', summary: 'Update Error', detail: data.message, life: 4500}); 
                        } else {
                            v_bookingoptour$.value.$reset();
                            emit('RefreshOptionalTourBooking', props.BookingID, props.ItineraryID)
                            isBookingDateValid.value = true
                            toast.add({severity:'success', summary: 'Success', detail:'Optional tour booking has been updated.', life: 3000});
                        }
                    } else {
                        toast.add({severity:'warn', summary: 'Error ' + data.status, detail: data.errorResponse, life: 4500});        
                    }
                })
            }
        }

        //=================Form Validation=================
        const bookingOptionalTourRules = computed(() => {
            return {
                optionaltour_id: { required },
                pax: { required },
                booking_date: { required },
            }
        })
        const v_bookingoptour$ = useVuelidate(bookingOptionalTourRules, optionalTourBookingForm)
        const validateForm = () => {
            v_bookingoptour$.value.$validate();
            if(!v_bookingoptour$.value.$error){
                return true
            } else {
                return false
            }
        }
        
        return {
            optionalTour,
            selectedOptionalTour,
            onOptionalTourSelect,
            saveOptionalTourBooking,
            errorMessage,

            v_bookingoptour$,
            isBookingDateValid,
            optionalTourBookingForm,
            editOptionalTourBooking,
            isEditOptionalTourBooking,
            updateOptionalTourBooking,
        }
    },
}
</script>

<style lang="scss" scoped>

</style>