<template>
<div class="booking-optionaltour">
    <div style="margin-top: 3px; height: 310px">
        <!-- <LoadingDataError v-if="loadingError.isError" :ErrorCode="loadingError.errorCode" /> -->

        <DataTable
            :value="optionalTourBooking"
            responsiveLayout="scroll"
            scrollHeight="280px"
            v-model:selection="selectedOptionalTourBooking" 
            selectionMode="single"
            dataKey="id"
            v-if="!loadingError.isError"
        >
            <!-- <Column field="id" header="ID" :style="{'min-width':'30px'}"></Column> -->
            <Column field="desc" header="Description" :style="{'min-width':'310px'}"></Column>
            <Column field="booking_date" header="Date" :style="{'min-width':'110px'}">
                <template #body="{data}">
                    {{data.booking_date != null ? dayjs(data.booking_date).format('DD-MMM-YYYY') : ''}}
                </template>
            </Column>
            <Column field="pax" header="Pax" :style="{'min-width':'40px'}"></Column>
            <Column field="child" header="Child" :style="{'min-width':'40px'}"></Column>

            <Column :exportable="false" :style="{'min-width':'75px', 'width':'75px', 'padding':'0.4rem 0rem', 'text-align':'right'}">
                <template #body="slotProps">
                    <Button v-if="can('update', 'booking')" icon="pi pi-pencil" class="p-button-rounded p-button-outlined p-button-success small-datatable-button p-mr-2" @click="onEditOptionalTourBooking(slotProps.data)" />
                    <Button v-if="can('delete', 'booking')" icon="pi pi-trash" class="p-button-rounded p-button-outlined p-button-danger small-datatable-button p-mr-2" @click="confirmDeleteOptionalTourBooking(slotProps.data)" />
                </template>
            </Column>
        </DataTable>
    </div>

    <hr style="border: 0; border-top: 1px solid var(--surface-d);"/>
    <div style="display: flex; align-self: flex-end; margin-bottom: 10px">
        <Button v-if="can('create', 'booking')" label="Add Optional Tour" class="p-button p-button-sm" @click="openBookingOptionalTourForm()" :disabled="bookingID == 0 || loadingError.isError" />
        <Dialog v-model:visible="isBookingOptionalTourFormShow" header="Optional Tour Booking" :style="{width: '53rem', margin: '0px 5px'}" :modal="true">
            <BookingOptionalTourForm ref="BookingOptionalTourFormRef" :BookingID="bookingID" :ItineraryID="itineraryID" @RefreshOptionalTourBooking="getOptionalTourBooking" />
        </Dialog>
    </div>
</div>
</template>

<script>
import { ref, reactive, nextTick } from 'vue';
import { useConfirm } from "primevue/useconfirm";
import { useToast } from "primevue/usetoast";
import { useAbility } from '@casl/vue';
import BookingOptionalTourForm from './BookingOptionalTourForm.vue';
import BookingService from '../service/BookingService';
import dayjs from 'dayjs';

export default {
    setup() {
        const confirm = useConfirm()
        const toast = useToast()
        const { can } = useAbility()
        let optionalTourBooking = ref([])
        let selectedOptionalTourBooking = ref()
        let loadingError = reactive({isError: false, errorCode: ''})
        const isBookingOptionalTourFormShow = ref(false)
        let bookingID = ref(0)
        let itineraryID = ref(0)
        const BookingOptionalTourFormRef = ref()

        const bookingService = ref(new BookingService())

        //==============Method==============
        const openBookingOptionalTourForm = () => {
            isBookingOptionalTourFormShow.value = true
        }

        const getOptionalTourBooking = (Booking_ID, Itinerary_ID) => {
            if(Booking_ID != 0) {
                //SEND BOOKING_ID & ITINERARY_ID TO TRANSPORT BOOKING FORM'S PROPS
                bookingID.value = Booking_ID
                itineraryID.value = Itinerary_ID

                optionalTourBooking.value.length = 0;
                selectedOptionalTourBooking.value = null

                bookingService.value.getOptionalTourBooking(Booking_ID).then(data => {
                    if(!data.errorResponse) {
                        optionalTourBooking.value = data

                        loadingError.isError = false
                    } else {
                        loadingError.isError = true
                        loadingError.errorCode = data.status
                    }
                })
            }
        }

        const onEditOptionalTourBooking = (data) => {
            selectedOptionalTourBooking.value = data
            
            isBookingOptionalTourFormShow.value = true
            nextTick(() => {
                BookingOptionalTourFormRef.value.editOptionalTourBooking(selectedOptionalTourBooking.value)
            })
        }

        const confirmDeleteOptionalTourBooking = (data) => {
            confirm.require({
                message: 'Are you sure you want to delete this optional tour booking?',
                header: 'Delete Confirmation',
                icon: 'pi pi-info-circle',
                acceptClass: 'p-button-danger',
                accept: () => {
                    const bookingOptourID = data.id
                    bookingService.value.deleteBookingOptionalTour(bookingOptourID).then((data) => {
                        if(!data.errorResponse){
                            optionalTourBooking.value = optionalTourBooking.value.filter(val => val.id !== bookingOptourID);
                            toast.add({severity:'success', summary: 'Success', detail:'Optional tour booking has been deleted.', life: 3000});
                        } else {
                            toast.add({severity:'warn', summary: 'Delete Error ' + data.status, detail: data.errorResponse, life: 4500});
                        }
                    })
                }
            });
        }

        return {
            can,
            optionalTourBooking,
            selectedOptionalTourBooking,
            loadingError,
            isBookingOptionalTourFormShow,
            bookingID,
            itineraryID,

            openBookingOptionalTourForm,
            BookingOptionalTourFormRef,
            onEditOptionalTourBooking,
            getOptionalTourBooking,
            confirmDeleteOptionalTourBooking,
            dayjs,
        }
    },
    components: {
        BookingOptionalTourForm,
    }
}
</script>

<style lang="scss" scoped>

</style>